import { IMarker } from '../types';
import { IPointFeature } from '../types/IPointFeature';

// SuperCluster requires us to use the format of a GeoJSONFeature
// with the type "Point"
export function mapMarkerToJsonFeature(marker: IMarker): IPointFeature {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [marker.lng, marker.lat],
    },
    properties: {
      id: marker.id,
      lat: marker.lat,
      lng: marker.lng,
    },
  };
}
