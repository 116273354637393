import { DEFAULT_ZOOM, Map } from '@features/shared/components/map/ui';
import { MapCameraChangedEvent, MapEvent } from '@vis.gl/react-google-maps';
import { ComponentProps, useState } from 'react';
import { IClub } from 'src/features/club-page/types/IClub';
import { BoundingBox } from '../map/types';
import { Markers } from './Markers';

interface IMapContainerProps extends ComponentProps<typeof Map> {
  clubs: IClub[];
  selectedClubId?: number;
  padding?: number | google.maps.Padding;
  onMarkerClick?: (clubId: number) => void;
  onEnableUserLocation?: () => void;
}

export interface IMapState {
  zoom: number;
}

export function ClubsMap({
  clubs,
  selectedClubId,
  padding,
  onMarkerClick,
  onEnableUserLocation,
  ...props
}: IMapContainerProps) {
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
  const [bounds, setBounds] = useState<BoundingBox>([0, 0, 0, 0]);

  const handleIdle = (event: MapEvent) => {
    const newZoom = event.map.getZoom();
    if (newZoom) {
      setZoom(newZoom);
    }
    const bounds = event.map.getBounds()?.toJSON();

    if (bounds) {
      setBounds([
        bounds?.west || 0,
        bounds?.south || 0,
        bounds?.east || 0,
        bounds?.north || 0,
      ]);
    }
  };

  const handleZoomChange = (event: MapCameraChangedEvent) => {
    setZoom(event.detail.zoom);
  };

  return (
    <Map
      onIdle={handleIdle}
      onZoomChanged={handleZoomChange}
      onEnableUserLocation={onEnableUserLocation}
      {...props}
    >
      <Markers
        clubs={clubs}
        zoom={zoom}
        bounds={bounds}
        padding={padding}
        selectedClubId={selectedClubId}
        onMarkerClick={onMarkerClick}
      />
    </Map>
  );
}
