import { useEffect, useState } from 'react';
import { useSupercluster } from './use-supercluster';
import { IMarker, ICluster, BoundingBox } from '../types';
import { isClusterFeature } from '../utils/is-cluster-feature';

type IClusterResult = {
  markers: IMarker[];
  clusters: ICluster[];
};

export function useClusters(
  markerData: IMarker[],
  { bounds, zoom }: { bounds: BoundingBox; zoom: number },
) {
  const [{ markers, clusters }, setResults] = useState<IClusterResult>({
    markers: [],
    clusters: [],
  });

  const supercluster = useSupercluster(markerData);

  useEffect(() => {
    if (supercluster) {
      const result = supercluster.getClusters(bounds, zoom);

      const newResults = result.reduce(
        (acc: IClusterResult, item) => {
          if (isClusterFeature(item)) {
            acc.clusters.push({
              id: item.properties.cluster_id,
              lat: item.geometry.coordinates[1],
              lng: item.geometry.coordinates[0],
              point_count: item.properties.point_count,
            });
          } else {
            acc.markers.push({
              id: item.properties.id,
              lat: item.geometry.coordinates[1],
              lng: item.geometry.coordinates[0],
            });
          }

          return acc;
        },
        {
          markers: [],
          clusters: [],
        } as IClusterResult,
      );

      setResults(newResults);
    }
  }, [supercluster, zoom, bounds]);

  return { markers, clusters, supercluster };
}
