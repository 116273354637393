import { useCallback } from 'react';
import { getLocationByAddress } from 'src/features/shared/services/geolocation/get-location-by-address';
import { ClubWithCmsData } from 'src/features/pg-funnel/utils/funnel';
import { IClubWithGeoDistance } from 'src/features/shared/types/IClubWithGeoDistance';
import { regexSort } from 'src/utils/regex-sort';
import { addDistanceToClubs } from 'utils/add-distance-to-clubs';
import getClosestClubs from 'utils/get-closest-clubs';
import { getFilteredClubs } from 'utils/get-filtered-clubs';
import { isCenterOfNetherlands } from 'src/features/clubs-search/utils/is-center-of-netherlands';

// Deprecated use ./src/feature/club-search/hooks/use-club-search.ts instead
export default function useClubSearch(
  callback: ((clubs: ClubWithCmsData[]) => void) | null,
  clubs: ClubWithCmsData[] | null,
  numberOfClubsToShow?: number | null,
) {
  const searchClubs = async (value: string): Promise<ClubWithCmsData[]> => {
    const geolocation = await getLocationByAddress(value);

    // Workaround for Google Geolocation API returning the center of the Netherlands
    // when it can't find a location.
    if (!geolocation || isCenterOfNetherlands(geolocation)) {
      callback && callback([]);
      return Promise.resolve([]);
    }

    const clubsWithGeoDistance: IClubWithGeoDistance[] = addDistanceToClubs(
      clubs as ClubWithCmsData[],
      geolocation,
      'geoDistanceFromSearch',
    );
    const closestClubs = getClosestClubs(
      clubsWithGeoDistance,
      numberOfClubsToShow,
      'geoDistanceFromSearch',
    );

    const filteredClubs = getFilteredClubs(clubs || [], value);
    const sortedClubs = regexSort<ClubWithCmsData>(
      filteredClubs,
      value,
      'name',
    );

    const combinedClubs = removeDuplicateClubs([
      ...closestClubs,
      ...sortedClubs,
    ]);

    return combinedClubs;
  };

  const setClosestClubs = useCallback(
    async (value: string) => {
      if (!value) return callback && callback([]);
      const results = await searchClubs(value);
      callback && callback(results);
    },
    [callback, clubs],
  );

  return {
    searchClubs,
    setClosestClubs,
  };
}

function removeDuplicateClubs(clubs: ClubWithCmsData[]) {
  return clubs.filter((club, index, self) => {
    return index === self.findIndex((c) => c.id === club.id);
  });
}
