import { useMemo } from 'react';
import { IMarker, BoundingBox } from '../types';
import { getBounds } from 'geolib';
import { useClusters } from './use-clusters';

export function useMarkers(
  markersData: IMarker[] = [],
  mapState: { bounds: BoundingBox; zoom: number },
) {
  const markersBounds = useMemo(() => {
    if (!markersData.length) {
      return null;
    }
    const {
      maxLat: north,
      minLat: south,
      maxLng: east,
      minLng: west,
    } = getBounds(markersData);
    return {
      north,
      south,
      east,
      west,
    };
  }, [markersData]);

  const { clusters, markers, supercluster } = useClusters(
    markersData,
    mapState,
  );

  return { clusters, markers, markersBounds, supercluster };
}
