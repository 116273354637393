import { AdvancedMarker } from '@vis.gl/react-google-maps';
import ClusterIcon from '@assets/markers/marker-cluster.svg';
import { ComponentProps } from 'react';
import styles from './index.module.scss';

interface IClusterProps extends ComponentProps<typeof AdvancedMarker> {
  count: number;
}

export const Cluster = ({ count, ...props }: IClusterProps) => (
  <AdvancedMarker {...props}>
    <div className={styles.container}>
      <ClusterIcon width={53} height={69} />
      <span>{count}</span>
    </div>
  </AdvancedMarker>
);
