import { useEffect, useState } from 'react';
import Supercluster from 'supercluster';
import { mapMarkerToJsonFeature } from '../utils/mapMarkerToJsonFeature';
import { IMarker } from '../types';
import { IClusterProperties } from '../types/IClusterFeature';
import { IPointFeatureProperties } from '../types/IPointFeature';

export type SuperClusterInstance = Supercluster<
  IPointFeatureProperties,
  IClusterProperties
>;

// Creates the superCluster instance
export function useSupercluster(markers: IMarker[] = []) {
  const [supercluster, setSuperCluster] = useState<
    SuperClusterInstance | undefined
  >(undefined);

  useEffect(() => {
    const _supercluster = new Supercluster<
      IPointFeatureProperties,
      IClusterProperties
    >({
      radius: 60,
      maxZoom: 16,
      minZoom: 0,
    });

    const points = markers.map(mapMarkerToJsonFeature);

    _supercluster.load(points);

    setSuperCluster(_supercluster);
  }, [markers]);

  return supercluster;
}
