import { Marker } from '@features/shared/components/map/ui';
import { useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useMemo } from 'react';
import { Cluster } from 'src/features/shared/components/map/ui/cluster';
import { BoundingBox, ICluster } from '../map/types';
import { useMarkers } from '../map/hooks/use-markers';
import { IClub } from 'src/features/club-page/types/IClub';

interface IMapContainerProps {
  zoom: number;
  clubs: IClub[];
  selectedClubId?: number;
  bounds: BoundingBox;
  padding?: number | google.maps.Padding;
  onMarkerClick?: (clubId: number) => void;
}

export function Markers({
  zoom,
  clubs,
  selectedClubId,
  padding,
  bounds,
  onMarkerClick,
}: IMapContainerProps) {
  const map = useMap();

  const markerData = useMemo(() => {
    return clubs.map((club) => {
      return {
        id: club.id,
        lat: club.latitude,
        lng: club.longitude,
      };
    });
  }, [clubs]);

  const { clusters, markers, supercluster, markersBounds } = useMarkers(
    markerData,
    {
      bounds,
      zoom,
    },
  );

  const handleClusterClick = useCallback(
    ({ id, lat, lng }: ICluster) => {
      if (!supercluster) {
        return;
      }

      const newZoom = supercluster?.getClusterExpansionZoom(id);
      map?.setZoom(newZoom);
      map?.setCenter({ lat, lng });
    },
    [supercluster, map],
  );

  const handleMarkerClick = useCallback(
    (clubId: number) => {
      onMarkerClick?.(clubId);
    },
    [onMarkerClick],
  );

  useEffect(() => {
    if (markersBounds) {
      map?.fitBounds(markersBounds, padding);
    }
  }, [markersBounds, map, padding]);

  const markersMemoized = useMemo(() => {
    return markers.map((marker) => {
      return (
        <Marker
          key={marker.id}
          position={{
            lat: marker.lat,
            lng: marker.lng,
          }}
          selected={marker.id === selectedClubId}
          onClick={() => handleMarkerClick?.(marker.id)}
          scale={{ x: 54, y: 63 }}
        />
      );
    });
  }, [markers, handleMarkerClick, selectedClubId]);

  const clustersMemoized = useMemo(() => {
    return clusters.map((cluster) => {
      return (
        <Cluster
          key={cluster.id}
          position={{
            lat: cluster.lat,
            lng: cluster.lng,
          }}
          count={cluster.point_count}
          onClick={() => handleClusterClick(cluster)}
        />
      );
    });
  }, [clusters, handleClusterClick]);

  return (
    <>
      {markersMemoized}
      {clustersMemoized}
    </>
  );
}
